import gql from 'graphql-tag'

const ADD_LEAD = gql`
  mutation AddLead($input: CreateLeadInput!) {
    createLead(input: $input) {
      id
    }
  }
`

function routeLead(input) {
  const { routing, talkAbout } = input

  if (routing === "af") {
    return {
      leadMessageSent: "Refererred by Alexander Forbes via our website",
      leadServiceId: "afb",
      leadSourceDescription: "Alexander Forbes",
      campaignDescription: "Alexander Forbes referrals"
    }
  }

  if ((routing === "sav" && talkAbout === "Other") || talkAbout === "Savings") {
    return {
      leadServiceId: "sav"
    }
  }
  if ((routing === "cli" && talkAbout === "Other") || talkAbout === "Credit Life Cover") {
    return {
      contactMethod: "EMPLOYEE_CONTACT",
      leadServiceId: "cli"
    }
  }
  if ((routing === "fun" && talkAbout === "Other") || talkAbout === "Funeral Cover") {
    return {
      leadServiceId: "fun"
    }
  }
  if ((routing === "mcc" && talkAbout === "Other") || talkAbout === "Clearance Certificate") {
    return {
      contactMethod: "EMPLOYEE_CONTACT",
      leadServiceId: "mcc"
    }
  }

  return {}
}

function buildAddLeadMutationInput(input, source) {
  const { talkAbout, message, routing, ...inputData } = input

  let useSource

  if (["Fasta"].includes(source)) {
    useSource = source
  }

  return {
    input: {
      ...inputData,
      contactMethod: "PHONE",
      leadMessageSent: useSource ? `Lead from ${useSource}` : "visited our website and contacted us",
      leadMessageReturned: `I'd like to talk about: ${talkAbout}, Message: ${message}`,
      leadServiceId: "mkw",
      leadSourceDescription: useSource || "www.meerkat.co.za",
      leadPriority: 99,
      ...routeLead(input)
    }
  }
}

export {
  ADD_LEAD,
  buildAddLeadMutationInput
}