import React from 'react'
import DebtQualifying from '../components/leads/DebtQualifying'

function DebtLead() {
  if (typeof window === "undefined") return <div />

  const source = new URLSearchParams(window.location.search).get('utm_source')
  
  return <DebtQualifying formOnly={source === "Fasta"} source={source} />
}

export default DebtLead