import { useEffect, useState } from 'react'
import React from 'react'
import { Wizard } from '../../shared'
import { isRequired, isValidCellphoneNumber, isValidEmail } from '../../../lib/validators'
// import { printCurrency } from '../../../lib/utils'
// import {} from './DebtQualifying.style'
import styled from '@emotion/styled'
import { WizardModalContentsBody } from '../../shared/Wizard/Wizard.style'
import { TextFieldContainer } from '../../generic/TextInput/TextInput.style'
import { Mandatory } from '../../generic/form'
import { WhatsAppCheckboxContainer } from '../../generic/WhatsappCheckbox/WhatsappCheckbox.style'
import { CheckboxContainer } from '../../generic/Checkbox/Checkbox.style'
import { CardContent } from '../../generic/ChatCard/ChatCard.style'
import { Text } from '../../generic'
import firebase from 'gatsby-plugin-firebase'
import { ADD_LEAD, buildAddLeadMutationInput } from '../../../lib/contactForm'
import moment from 'moment'

const buildSteps = (setOpen, formOnly, source) => ([{
  name: "step1",
  nextAfterDelay: 100,
  noNext: true,
  nextSteps: formOnly ? ["form"] : ["employmentStatus"],
}, {
  name: "employmentStatus",
  beforeStep: () => {
    firebase.analytics().logEvent('DQ_EMPLOYMENT_STATUS')
  },
  title2: "What is your current employment status?",
  fieldNames: ["employmentStatus"],
  nextSteps: ({ employmentStatus }) => employmentStatus === "Unemployed" ? ["notQualify"] : ["income"],
  noNext: true,
  nextOnValue: true
}, {
  name: "income",
  beforeStep: () => {
    firebase.analytics().logEvent('DQ_INCOME')
  },
  title2: "What is your monthly salary on average?",
  fieldNames: ["income"],
  nextSteps: ["totalDebt"],
  noNext: true,
  nextOnValue: true
},
{
  name: "totalDebt",
  beforeStep: () => {
    firebase.analytics().logEvent('DQ_TOTAL_DEBT')
    window.parent && window.parent.postMessage({
      type: 'resize-iframe',
      payload: {
        width: "100%",
        height: "600px"
      }
    }, '*')
  },
  title2: "Roughly how much do you owe in total?",
  title3: "This includes: credit cards, home loans, vehicle finance, store cards, personal loans etc",
  fieldNames: ["totalDebt"],
  nextSteps: ({ totalDebt }) => totalDebt  === "Less than R30 000" ? ["notQualify"] : ["hasDC"],
  noNext: true,
  nextOnValue: true
},
{
  name: "hasDC",
  beforeStep: () => {
    firebase.analytics().logEvent('DQ_HAS_DC')
  },
  title2: <>Last Question!<br/>Are you currently under debt review?</>,
  title3: "*If you are registered with a debt counsellor to repay your debt, then unfortunately, we cannot assist you with our debt solution",
  fieldNames: ["hasDC"],
  nextSteps: ({ hasDC, totalDebt, employmentStatus }) => (employmentStatus === "Unemployed" || totalDebt === "Less than R30 000" || hasDC === "Yes") ? ["notQualify"] : ["form"],
  noNext: true,
  nextOnValue: true
}, {
  name: "notQualify",
  noNext: true,
  nextAfterDelay: 100,
  beforeStep: () => {
    firebase.analytics().logEvent('DQ_NOT_QUALIFY');
    setOpen(false);
    window.parent && window.parent.postMessage({
      type: 'goto-page',
      payload: {
        url: "https://connect.meerkat.co.za/debtsolutions-0"
      }
    }, '*')
  }
}, {
  beforeStep: () => {
    firebase.analytics().logEvent('DQ_CONTACT_FORM')
    window.parent && window.parent.postMessage({
      type: 'resize-iframe',
      payload: {
        width: "100%",
        height: "850px"
      }
    }, '*')
  },
  title2: formOnly ? "Please leave your details and a Meerkat consultant will call you back shortly." : "Leave us your details and we will call you back shortly",
  name: "form",
  fieldNames: ["firstName", "surname", "email", "cellNumber", "persona", "whatsAppNumber", "marketingConsent"],
  component: () => <center style={{ margin: "16px" }}><Mandatory />
  <Text style={{ fontSize: 10, marginTop: 24 }}>By clicking Call Me Back, you understand that Meerkat will process your info to respond to you in accordance with our Privacy Policy.</Text>
  </center>,
  nextSteps: ["done"],
  nextTitle: "Call Me Back"
}, {
  name: "done",
  noNext: true,
  beforeStep: async ({ apolloClient, chatState }) => {
    apolloClient.mutate({ mutation: ADD_LEAD, variables: buildAddLeadMutationInput({
      firstName: chatState.firstName,
      surname: chatState.surname,
      cellNumber: chatState.cellNumber,
      email: chatState.email,
      whatsAppNumber: chatState.whatsAppNumber,
      persona: chatState.persona,
      talkAbout: "Debt Management",
      message: `Debt Qualifying Form${source ? ` - ${source}` : ""}`,
      routing: "dcc",
      leadSourceDescription: "Debt Qualifying Form",
    }, source) })
    // Create Contact in HS
    // https://api.hsforms.com/submissions/v3/integration/submit/6684549/b1237dd7-189d-4997-8bee-1bca235cf7f8

    const hsPersonaMapping = {
      NEWLY_INDEPENDENT: "personal_2",
      YOUNG_PROFESSIONAL: "persona_1",
      YOUNG_FAMILY: "persona_3",
      MATURE: "persona_4"
    }

    const result = await fetch('https://api.hsforms.com/submissions/v3/integration/submit/6684549/ccbf021f-b57b-4780-9129-8e11848bacdf', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      },
      body: JSON.stringify({
        "submittedAt": moment().valueOf(),
        "fields": [
          {
            "objectTypeId": "0-1",
            "name": "email",
            "value": chatState.email
          },
          {
            "objectTypeId": "0-1",
            "name": "phone",
            "value": chatState.cellNumber
          },
          {
            "objectTypeId": "0-1",
            "name": "firstname",
            "value": chatState.firstName
          },
          {
            "objectTypeId": "0-1",
            "name": "lastname",
            "value": chatState.surname
          },
          {
            "objectTypeId": "0-1",
            "name": "hs_persona",
            "value": hsPersonaMapping[chatState.persona]
          },
          {
            "objectTypeId": "0-1",
            "name": "id_like_to_talk_about",
            "value": "Debt Management"
          }
        ],
        "context": {
          "hutk": document.cookie.match(/hubspotutk=(.*?);/)[1],
          "pageUri": (new URLSearchParams(window.location.search)).get('pageUrl') || window.location.href,
          "pageName": (new URLSearchParams(window.location.search)).get('pageTitle') || `Debt Qualifying Form${source ? ` - ${source}` : ""}`,
        },
        "legalConsentOptions":{ 
          "consent":{
            "consentToProcess":true,
            "text":"By clicking Call Me Back, you understand that Meerkat will process your info to respond to you in accordance with our Privacy Policy.",
            "communications":[
              {
                "value":true,
                "subscriptionTypeId":999,
                "text":"I agree to receive marketing material"
              }
            ]
          }
        }
      })
    })
    const data = await result.json()
    console.log("HS", data)
    firebase.analytics().logEvent('DQ_DONE');
    setOpen(false);

    window.parent && window.parent.postMessage({
      type: 'goto-page',
      payload: {
        url: "https://connect.meerkat.co.za/debt-management-thank-you"
      }
    }, '*')

    if (formOnly && !window.parent) {
      window.location = "https://connect.meerkat.co.za/debt-management-thank-you"
    }
  }
}])

const fields = {
  employmentStatus: {
    type: "options",
    options: ["Employed", "Unemployed", "Self Employed", "Retired"],
    validate: [isRequired]
  },
  income: {
    type: "options",
    options: ["Less than R10 000", "R10 000 – R15 000", "R15 001 – R20 000", "R20 001 – R40 000", "R40 001 – R60 000", "R60 000 or more"],
    validate: [isRequired]
  },
  totalDebt: {
    type: "options",
    options: ["Less than R30 000", "R30 000 – R50 000", "R50 001 – R80 000", "R80 001 – R100 000", "R100 001 – R150 000", "R150 001 – R200 000", "R200 001 – R300 000", "R300 000 +"],
    validate: [isRequired]
  },
  hasDC: {
    type: "options",
    options: ["Yes", "No"],
    validate: [isRequired]
  },
  firstName: {
    label: "First Name",
    type: "text",
    validate: [isRequired],
    required: true
  },
  surname: {
    label: "Surname",
    type: "text",
    validate: [isRequired],
    required: true
  },
  email: {
    label: "Email",
    type: "text",
    validate: [isRequired, isValidEmail],
    required: true
  },
  cellNumber: {
    label: "Cell Number",
    type: "text",
    validate: [isRequired, isValidCellphoneNumber],
    required: true
  },
  whatsAppNumber: {
    label: "WhatsApp Number",
    type: "whatsappOpt"
  },
  marketingConsent: {
    label: "I agree to receive marketing material",
    type: "checkbox",
  },
  persona: {
    label: "Which statement describes you best?",
    type: "select",
    options: [{
      label: "I am newly independent",
      value: "NEWLY_INDEPENDENT"
    }, {
      label: "I am a young professional",
      value: "YOUNG_PROFESSIONAL"
    }, {
      label: "I have a young family",
      value: "YOUNG_FAMILY"
    }, {
      label: "Children have left the burrow",
      value: "MATURE"
    }],
    required: true,
    validate: [isRequired]
  }
}

function DebtQualifying({ formOnly, source }) {
  const [open, setOpen] = useState(true)
  useEffect(() => {
    firebase.analytics().logEvent('DQ_START')
  }, [])

  return <Wizard
    noCloseButton
    name="debtQualifying"
    open={open}
    setOpen={setOpen}
    steps={buildSteps(setOpen, formOnly, source)}
    renderContentContainer={styled.div`
    position: relative;
    width: 100vw;
    max-width: 800px;
    display: flex;
    flex-direction: column;`}
    renderContentsBody={styled.div`
    max-width: 800px !important;
    margin: auto;
    min-height: 0px;

    ${CardContent} {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
    }

    ${TextFieldContainer} {
      width: 300px;
      margin: auto; 
    }

    ${WhatsAppCheckboxContainer} {
      max-width: 600px;
      width: 90vw;
      margin: auto;
    }

    ${CheckboxContainer} {
      max-width: 600px;
      width: 90vw;
      margin: auto;
    }

    .MuiTextField-root {
      width: 280px;
    }
    `} 
    initialValues={{ marketingConsent: true }}
    fields={fields}
  />
}

export default DebtQualifying
